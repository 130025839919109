import React from 'react';
import Link from 'next/link';

import ArrowForward from '@src/components/Icons/ArrowForward';

import { ButtonPropTypes } from './Button.types';

const Button: React.FC<ButtonPropTypes> = ({
  attr,
  containerClassName = '',
  btnClassName = '',
  btnText = 'Get in touch',
  primary,
  secondary,
  tertiary,
  quaternary, // Used for download files
  href,
  align = 'justify-center',
  handleOnClick,
  disabled,
  target = '_self',
  svgClassName = '',
}) => {
  return (
    <div className={`flex items-center ${align} ${containerClassName}`}>
      {primary && !href && (
        <button
          data-test={attr}
          onClick={handleOnClick}
          title={disabled ? 'Button is disabled' : btnText}
          className={`h-[4.4rem] rounded-[0.6rem] bg-primary px-[2rem] text-[1.6rem] font-[400] leading-[1.8rem] tracking-[-0.0304rem] text-[#F6F6F6] transition-colors duration-300 ease-in-out ${btnClassName} ${
            disabled
              ? 'pointer-events-none cursor-not-allowed border-[0.1rem] border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]'
              : 'common-btn-transition hover:bg-primary--v2'
          }`}
        >
          {btnText}
        </button>
      )}

      {primary && href && (
        <Link
          data-test={attr}
          href={href}
          title={disabled ? 'Button is disabled' : btnText}
          target={target}
          className={`flex h-[4.4rem] items-center justify-center rounded-[0.6rem] bg-primary px-[2rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-[#F6F6F6] transition-colors duration-300 ease-in-out ${btnClassName} ${
            disabled
              ? 'pointer-events-none cursor-not-allowed border-[0.1rem] border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]'
              : 'common-btn-transition hover:bg-primary--v2'
          }`}
        >
          {btnText}
          {btnText === 'Learn more' && (
            <span className='screen-reader-text'>
              Please click here to learn more about our products
            </span>
          )}
        </Link>
      )}

      {secondary && !href && (
        <button
          data-test={attr}
          onClick={handleOnClick}
          title={disabled ? 'Button is disabled' : btnText}
          className={`bg-transparent h-[4.4rem] rounded-[0.6rem] border-[0.1rem] border-primary px-[2rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-primary transition-colors duration-300 ease-in-out ${btnClassName} ${
            disabled
              ? 'pointer-events-none cursor-not-allowed border-[0.1rem] !border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]'
              : 'common-btn-transition hover:border-primary--v2 hover:text-primary--v2'
          }`}
        >
          {btnText}
        </button>
      )}

      {secondary && href && btnText !== 'Learn more' && (
        <Link
          data-test={attr}
          href={href}
          title={disabled ? 'Button is disabled' : btnText}
          target={target}
          className={`bg-transparent flex h-[4.4rem] items-center justify-center rounded-[0.6rem] border-[0.1rem] border-primary px-[2rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-primary transition-colors duration-300 ease-in-out ${btnClassName} ${
            disabled
              ? 'pointer-events-none cursor-not-allowed border-[0.1rem] !border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]'
              : 'common-btn-transition hover:border-primary--v2 hover:text-primary--v2'
          }`}
        >
          {btnText}
          {btnText === 'Learn more' && (
            <span className='screen-reader-text'>
              Please click here to learn more about our products
            </span>
          )}
        </Link>
      )}

      {tertiary && href && (
        <Link
          data-test={attr}
          href={href}
          title={disabled ? 'Button is disabled' : btnText}
          target={target}
          className={`common-btn-transition bg-transparent flex items-center justify-center rounded-[0.6rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-primary transition-colors duration-300 ease-in-out ${btnClassName} ${
            disabled
              ? '!bg-transparent pointer-events-none cursor-not-allowed !text-[#B9B9B9]'
              : 'common-btn-transition hover:text-primary--v2'
          }`}
        >
          <span className={`mr-[0.8rem]`}>
            {btnText}{' '}
            {btnText === 'Learn more' && (
              <span className='screen-reader-text'>
                Please click here to learn more about our products
              </span>
            )}
          </span>
          <ArrowForward
            className={`mt-[0.1rem] h-[1.4rem] w-[0.646rem] text-primary hover:text-primary--v2 ${svgClassName} ${
              disabled ? 'pointer-events-none !text-[#B9B9B9]' : 'common-btn-transition'
            }`}
          />
        </Link>
      )}

      {quaternary && href && (
        <a
          data-test={attr}
          href={href}
          title={disabled ? 'Button is disabled' : btnText}
          target={target}
          className={`flex h-[4.4rem] items-center justify-center rounded-[0.6rem] bg-primary px-[2rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-[#F6F6F6] transition-colors duration-300 ease-in-out ${btnClassName} ${
            disabled
              ? 'pointer-events-none cursor-not-allowed border-[0.1rem] border-[#B9B9B9] !bg-background-primary !text-[#B9B9B9]'
              : 'common-btn-transition hover:bg-primary--v2'
          }`}
        >
          {btnText}
          {btnText === 'Learn more' && (
            <span className='screen-reader-text'>
              Please click here to learn more about our products
            </span>
          )}
        </a>
      )}
    </div>
  );
};

export default Button;
