import React from 'react';

import { CommonPropTypes } from '../IconTypes';

const ArrowForward: React.FC<CommonPropTypes> = ({ className }) => {
  return (
    <svg className={`${className}`} viewBox='0 0 10 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.6875 7.28125C9.09375 7.65625 9.09375 8.3125 8.6875 8.6875L2.6875 14.6875C2.3125 15.0937 1.65625 15.0937 1.28125 14.6875C0.874999 14.3125 0.874999 13.6562 1.28125 13.2812L6.5625 8L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.874999 2.3125 0.874999 2.6875 1.28125L8.6875 7.28125Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ArrowForward;
