import { FetchGraphQLDataParamTypes } from './utils.types';

export default async function fetchGraphQLData<T>({
  url = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}?access_token=${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
  method = 'POST',
  query,
  variables,
  cache,
}: FetchGraphQLDataParamTypes): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    // Include the variables in the body if they exist
    const body = JSON.stringify(variables ? { query, variables } : { query, variables });

    const response = await fetch(url, {
      cache: cache
        ? cache
        : process.env.NEXT_PUBLIC_ENVIRONMENT === 'live'
          ? 'force-cache'
          : 'no-store',
      method,
      headers,
      body,
    });

    if (!response?.ok) {
      let errorDetails;
      try {
        const errorJson = await response.json();
        errorDetails = JSON.stringify(errorJson, null, 4);
      } catch (jsonError) {
        errorDetails = await response.text();
      }
      throw new Error(
        `Network response was not ok: ${response?.status} ${response?.statusText}\nDetails: ${errorDetails}`
      );
    }

    const data = await response.json();

    if (!data || !data?.data) {
      throw new Error('Response JSON does not contain data.');
    }

    return data?.data as T;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Fetching data failed: ${error?.message}`);
    } else {
      throw new Error('An error occurred while fetching data from the API.');
    }
  }
}
