import React from 'react';

import { ExpanderPropTypes } from './Expander.types';

const Expander: React.FC<ExpanderPropTypes> = ({ className = '', children }) => {
  return (
    <div
      className={`mx-auto ml-[-2.4rem] mr-[calc((-100vw+100%)/2)] h-[100%] w-screen md:ml-[-6.3rem] lg:ml-[calc((-100vw+100%)/2)] ${className}`}
    >
      {children}
    </div>
  );
};

export default Expander;
