import React from 'react';

import { CommonPropTypes } from '../IconTypes';

const Facebook: React.FC<CommonPropTypes> = ({ className }) => {
  return (
    <svg
      className={className}
      width='4'
      height='5'
      viewBox='0 0 4 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='2' cy='2.5' r='2' fill='currentColor' />
    </svg>
  );
};

export default Facebook;
